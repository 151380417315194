<template>
    <div class="main-container">

        <Header />
        <div class="slider-area">     
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="breadcrumb">
                                <h1>Notícias</h1>
                                <b-breadcrumb class="page-breadcrumb" :items="items"></b-breadcrumb>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>                  
        <div class="page-wrapper section-space--inner--10">
            
        <div class="latest-news-area section-padding30">
            <div class="container">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6" v-for="noticia in lista_noticias.noticias" :key="noticia.id">
                    <Noticias :noticia="noticia" />
                    </div>
                </div>
            </div>
        </div>
        <!-- Services Area End -->
        
            <!--Service section end-->
        </div>

        <Footer />

        <!-- <OffCanvasMobileMenu /> -->

        <!-- back to top start -->
        <back-to-top class="scroll-top" bottom="60px">
            <i class="ion-android-arrow-up"></i>
        </back-to-top>
        <!-- back to top end -->
    </div>
</template>

<script>
    import axios from 'axios';
    import Header from '@/components/Header';
    import Breadcrumb from '../components/Breadcrumb';
    import Noticias from '../components/Artigos';
    import Footer from '../components/Footer';
    //import OffCanvasMobileMenu from '@/components/OffCanvasMobileMenu';
    export default {
        components: {
            Header,
            Breadcrumb,
            Noticias,
            Footer,
            //OffCanvasMobileMenu
        },
        created(){
            doSomething.call(this);
        },
        watch: {
        '$route' () {
            doSomething.call(this);
            }
        },
        data() {
            return {
                items: [
                    {
                        text: 'Home',
                        to: "/"
                    },
                    {
                        text: 'Notícias',
                        active: true
                    }
                ],
                lista_noticias: []
               
            }
        },
        metaInfo: {
            title: 'Vix Inpeções',
            titleTemplate: `%s - noticias`,
            meta: [{
            name: 'description',
            content: 'Vix Inpeções'
            },
            {
            name: 'keywords',
            content: 'Vix Inspeções, inspenções, vistoria, vistorias de veículos escolares, veículos pesados, veículos de passeios'
            }
            ],
            htmlAttrs: {
                lang: 'pt-BR',
                amp: true
            }
        }
    }

    function doSomething() {
       
        axios.get(`https://sistema.gouarts.com/api/lista_noticias?cliente=vitoriainspecoes`).then(res => {
            this.lista_noticias = res.data;
            
        }).catch(err => {
            console.log(err);
        })
}
</script>
<style scoped>
.slider-area {
    height: 250px;    
}
.services-area1 {
    margin-top: 100px;
}
</style>


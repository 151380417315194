<template>
    <div class="single-news mb-30">
        <div class="news-img">
             <img :src="'https://sistema.gouarts.com/uploads/vitoriainspecoes/' + noticia.foto" class="img-fluid" :alt="noticia.titulo">
                <div class="news-date text-center">
                   
                        <p>{{ noticia.publicacao | moment("D") }}</p>
                        <span>{{ noticia.publicacao | moment("MMM") }}</span>
                </div>
               
                <div class="news-caption">
                    <h2><router-link :to="`/noticia/${noticia.id}/${noticia.slug}`"> {{ noticia.titulo }}</router-link></h2>
                    <router-link class="d-btn" :to="`/noticia/${noticia.id}/${noticia.slug}`">SAIBA MAIS </router-link>
                 </div>
        </div>
    </div>        
</template>

<script>
import moment from 'moment'
    export default {
        props: ['noticia'],
        
    };
</script>
